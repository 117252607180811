import React from 'react'
import compact from 'just-compact'
import { ExpertModel } from '@heights/heights-ui-library'
import { Experts } from '@heights/heights-ui-components'
import { RichTextContent } from '@/components/RichTextContent'
import { withErrorBoundary } from '@/utils'
import { useCtaAction } from '@/hooks/useCtaAction'
import { transformImage } from '@/utils/contentful'
import { textItem as textItemSchema } from '@/schemas/textItem'
import { sectionDataHookFactory } from '@/hooks'
import type { SingleComponentPickerItem } from '../../SingleComponentPicker'
import { asResolverQuery } from '@/utils/groq'

type Props = {
  data: SingleComponentPickerItem
}

const [textItemQuery, textItemType] = textItemSchema.builder
  .map((item) => ({
    authorImage: item.fields.authorImage?.resolve(['fields']).use(),
  }))
  .use()

const useSectionData = sectionDataHookFactory({
  textItems: asResolverQuery<typeof textItemType>(
    'fields.components',
    textItemQuery,
    '@->_type == "textItem"'
  ),
})

export const ContentfulSectionExpertsSection: React.FunctionComponent<Props> =
  withErrorBoundary(({ data }) => {
    const section = useSectionData(data._id)
    const ctaActions = useCtaAction(
      compact(
        section?.ctaActions?.length ? section.ctaActions : [section?.ctaAction]
      )
    )

    if (!section) {
      return null
    }

    return (
      <Experts
        version="v2"
        backgroundColor={section.fields?.backgroundColor ?? undefined}
        backgroundImage={
          transformImage(section.backgroundImage?.fields, {
            width: 1920,
            fullscreen: true,
          }) ?? undefined
        }
        title={section.fields?.title}
        description={
          section.fields?.description ? (
            <RichTextContent
              inheritParagraphFont
              document={section.fields?.description}
            />
          ) : null
        }
        cta={ctaActions ?? undefined}
        experts={compact(
          section?.textItems?.map((textItem): ExpertModel | null => {
            const image = transformImage(textItem?.authorImage?.fields, {
              width: 400,
              aspectRatio: 2 / 3,
              objectFit: 'cover',
              objectPosition: 'center',
            })
            if (!image || !textItem) return null
            return {
              image,
              name: textItem.fields?.author ?? '',
              title: textItem.fields?.title ?? '',
              description: textItem.fields?.plainText,
            }
          }) ?? []
        )}
      />
    )
  })

export default ContentfulSectionExpertsSection
