import React from 'react'
import Image from 'next/image'
import { asset } from '@/schemas/asset'
import { useData } from '@/hooks'
import { Box } from 'theme-ui'
import { lqipImageUrl, transformImageUrl } from '@/utils/contentful'
import { DataSourceType } from '@/hooks/useData'

type Props = {
  id: string
}

const [query, type] = asset.builder.filter('_id == $id').first().use()

export const RichTextImage: React.FC<Props> = ({ id }) => {
  const reader = useData<typeof type>(DataSourceType.CONTENTFUL, query, { id })
  const image = reader()

  if (!image?.fields?.file?.details?.image || !image.fields.file.url) {
    return null
  }

  const { title, description, file } = image.fields
  const { width, height } = image.fields.file.details.image

  return (
    <Box
      sx={{
        paddingY: 5,
      }}
    >
      <Image
        style={{
          maxWidth: '100%',
          height: 'auto',
        }}
        alt={title ?? description ?? ''}
        width={width ?? 0}
        height={height ?? 0}
        src={transformImageUrl(file.url!, {
          width: 1000,
        })}
        placeholder="blur"
        blurDataURL={lqipImageUrl(file.url!)}
      />
    </Box>
  )
}
