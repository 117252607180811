import React, { FC } from 'react'
import { Box } from 'theme-ui'

const shouldCatch = process.env.VERCEL_ENV !== 'production'

export function withErrorBoundary<P>(Component: FC<P>): FC<P> {
  return (props) => {
    try {
      return Component(props)
    } catch (error: unknown) {
      if (error && typeof error === 'object' && error instanceof Promise) {
        throw error
      }

      if (shouldCatch) {
        return (
          <Box>
            <strong>Failed to render component</strong>
            <pre>
              {error && typeof error === 'object'
                ? error.toString()
                : String(error)}
              {JSON.stringify(props, null, 2)}
            </pre>
          </Box>
        )
      }
      throw error
    }
  }
}
