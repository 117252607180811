import React, { ReactNode, useMemo } from 'react'
import Case from 'case'
import { Themed } from '@theme-ui/mdx'
import {
  documentToReactComponents,
  Options,
  RenderNode,
} from '@contentful/rich-text-react-renderer'
import { Document, BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { ensureInternalUrlTrailingSlash } from '@/utils/string'
import { AppLink, Text } from '@heights/heights-ui-library'
import { extractReactNodeText } from '@/utils/browser'
import { ComponentPicker } from '../ComponentPicker'
import { RichTextImage } from './RichTextImage'

type Props = {
  document?: Document | null
  removeFirstHeadingMargin?: boolean
  inheritParagraphFont?: boolean
  renderNodeOverrides?: RenderNode
}

const defaultOptions: Options = {
  renderMark: {
    [MARKS.BOLD]: (text): ReactNode => <Themed.strong>{text}</Themed.strong>,
    [MARKS.ITALIC]: (text): ReactNode => <Themed.em>{text}</Themed.em>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: ({ data: { uri } }, children): ReactNode => (
      <AppLink
        disableDefaultVariant
        to={ensureInternalUrlTrailingSlash(uri)}
        className="styles/a"
      >
        {children}
      </AppLink>
    ),
    [BLOCKS.PARAGRAPH]: (_, children): ReactNode => (
      <Themed.p>{children}</Themed.p>
    ),
    [BLOCKS.OL_LIST]: (_, children): ReactNode => (
      <Themed.ol>{children}</Themed.ol>
    ),
    [BLOCKS.UL_LIST]: (_, children): ReactNode => (
      <Themed.ul>{children}</Themed.ul>
    ),
    [BLOCKS.LIST_ITEM]: (_, children): ReactNode => {
      return <Themed.li>{children}</Themed.li>
    },
    [BLOCKS.HR]: (): ReactNode => <Themed.thematicBreak />,
    [BLOCKS.QUOTE]: (_, children): ReactNode => (
      <Themed.blockquote>{children}</Themed.blockquote>
    ),
    [BLOCKS.HEADING_2]: (_, children): ReactNode => {
      const id = Case.kebab(extractReactNodeText(children).toLowerCase())
      return <Themed.h2 id={id}>{children}</Themed.h2>
    },
    [BLOCKS.HEADING_3]: (_, children): ReactNode => (
      <Themed.h3>{children}</Themed.h3>
    ),
    [BLOCKS.HEADING_4]: (_, children): ReactNode => (
      <Themed.h4>{children}</Themed.h4>
    ),
    [BLOCKS.HEADING_5]: (_, children): ReactNode => (
      <Themed.h5>{children}</Themed.h5>
    ),
    [BLOCKS.HEADING_6]: (_, children): ReactNode => (
      <Themed.h6>{children}</Themed.h6>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node): ReactNode => {
      if (node.data?.target?.sys?.id) {
        return (
          <ComponentPicker
            data={[
              {
                _type: 'reference',
                _ref: node.data.target.sys.id,
              },
            ]}
          />
        )
      }

      return null
    },
    [BLOCKS.EMBEDDED_ASSET]: (node): ReactNode => {
      if (node?.data?.target?.sys?.id) {
        return <RichTextImage id={node.data.target.sys.id} />
      }

      return null
    },
  },
}

export const RichTextContent: React.FC<Props> = ({
  document,
  renderNodeOverrides,
  inheritParagraphFont,
  removeFirstHeadingMargin,
}) => {
  const richTextOptions = useMemo((): Options => {
    return {
      ...defaultOptions,
      renderNode: {
        ...defaultOptions.renderNode,
        ...(removeFirstHeadingMargin
          ? {
              [BLOCKS.HEADING_2]: (_, children): ReactNode => {
                const id = Case.kebab(
                  extractReactNodeText(children).toLowerCase()
                )
                return (
                  <Themed.h2 id={id} sx={{ '&:first-of-type': { mt: 0 } }}>
                    {children}
                  </Themed.h2>
                )
              },
              [BLOCKS.HEADING_3]: (_, children): ReactNode => (
                <Themed.h3 sx={{ '&:first-of-type': { mt: 0 } }}>
                  {children}
                </Themed.h3>
              ),
              [BLOCKS.HEADING_4]: (_, children): ReactNode => (
                <Themed.h4 sx={{ '&:first-of-type': { mt: 0 } }}>
                  {children}
                </Themed.h4>
              ),
              [BLOCKS.HEADING_5]: (_, children): ReactNode => (
                <Themed.h5 sx={{ '&:first-of-type': { mt: 0 } }}>
                  {children}
                </Themed.h5>
              ),
              [BLOCKS.HEADING_6]: (_, children): ReactNode => (
                <Themed.h6 sx={{ '&:first-of-type': { mt: 0 } }}>
                  {children}
                </Themed.h6>
              ),
            }
          : {}),
        ...(inheritParagraphFont
          ? {
              [BLOCKS.PARAGRAPH]: (_, children): ReactNode => (
                <Text
                  as="p"
                  variant="styles.p"
                  className="break-words font-inherit [&:last-child]:mb-0"
                >
                  {children}
                </Text>
              ),
            }
          : {}),
        ...renderNodeOverrides,
      },
    }
  }, [renderNodeOverrides, inheritParagraphFont, removeFirstHeadingMargin])

  if (!document) {
    return null
  }

  return <>{documentToReactComponents(document, richTextOptions)}</>
}
