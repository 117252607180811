import { isValidElement, ReactNode } from 'react'

export const extractReactNodeText = (node: ReactNode) => {
  let text = ''
  if (typeof node === 'string' || typeof node === 'number') {
    text += String(node)
  } else if (Array.isArray(node)) {
    text += node.map(extractReactNodeText).join('')
  } else if (isValidElement(node)) {
    text += extractReactNodeText(node.props.children)
  }
  return text
}
